import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import Home from './pages';
import About from './pages/about';
import MineSweeper from './pages/minesweeper';
import SevenFigureSavings from './pages/sevenfiguresavings';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/past-work' element={<About />} />
                <Route path='/minesweeper' element={<MineSweeper />} />
                <Route path='/seven-figure-savings' element={<SevenFigureSavings />} />
            </Routes>
        </Router>
    );
}


export default App;












