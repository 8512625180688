import BnBLogo from './bnb_logo.png';
import BrillliantPadLogo from './brilliantpad_logo.png';
import DirtyCoastLogo from './dirtycoast_logo.png';
import EyeSupplyLogo from './eyesupply_logo.svg';
import OSEALogo from './osea_logo.svg';
import PaperTubeLogo from './papertube_logo.png';
import TascLogo from './tasc_logo.svg';
import TheResetLogo from './thereset_logo.svg';
import ThirdLoveLogo from './thirdlove_logo.svg';
import UroTuningLogo from './urotuning_logo.png';

export {
  BnBLogo,
  BrillliantPadLogo,
  DirtyCoastLogo,
  EyeSupplyLogo,
  OSEALogo,
  PaperTubeLogo,
  TascLogo,
  TheResetLogo,
  ThirdLoveLogo,
  UroTuningLogo
};