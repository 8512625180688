import React from "react";
import * as Styled from "./SavingsTableElements";

const InputUnit = (props) => (
  <Styled.InputUnitContainer>
    <label htmlFor={props.name}>{props.label}</label>
    <input type="number" id={props.name} name={props.name} defaultValue={props.default} onChange={props.handleChange} />
  </Styled.InputUnitContainer>
);

const ResultsBanner = (props) => {
  const failureMessage = (
    <>
      Sorry, you won't be a millionaire with those savings amounts :(<br/>
      <span style={{fontSize: "18px"}}>We recommend making some adjustments.</span>
    </>
  );

  return (
    <Styled.ResultsBanner className={props.finalResult.success ? "success" : "sorry"}>
      {props.finalResult.success ? props.finalResult.message : failureMessage}
    </Styled.ResultsBanner>
  );
};

export const SavingsHeader = () => (
  <>
    <Styled.SiteName>Seven Figure Savings!!</Styled.SiteName>
    <Styled.Subtitle>Free Simple Savings Calculator</Styled.Subtitle>
  </>
);

export const Instruction = (props) => (
  <Styled.StaticText>
    Adjust the numbers in the boxes below to find out when<br />
    you'll have a million dollars in savings and become a millionaire!
  </Styled.StaticText>
);

export const UserForm = (props) => (
  <Styled.InputContainer>
    <InputUnit
      label="Current Age"
      name="currentAge"
      default="25"
      handleChange={props.handleChange}
    />
    <InputUnit
      label="Salary ($)"
      name="salary"
      default="50000"
      handleChange={props.handleChange}
    />
    <InputUnit
      label="Amount Saving (%)"
      name="amountSaving"
      default="10"
      handleChange={props.handleChange}
    />
    <InputUnit
      label="Interest Rate (%)"
      name="interestRate"
      default="0"
      handleChange={props.handleChange}
    />
    <InputUnit
      label="Existing Savings ($)"
      name="existingSavings"
      default="0"
      handleChange={props.handleChange}
    />
    <InputUnit
      label="Retirement Age"
      name="retirementAge"
      default="65"
      handleChange={props.handleChange}
    />
  </Styled.InputContainer>
);

export const BasicResults = (props) => (
  <>
    <ResultsBanner finalResult={props.finalResult} />
    <Styled.ShowButton onClick={props.handleClick}>
      Click to see full results
    </Styled.ShowButton>
  </>
);

export const CalcTable = (props) => (
  <Styled.CalculationTableContainer className={props.showTable && "open"}>
    <Styled.CalculationTable>
      <tbody>
        {props.children}
      </tbody>
    </Styled.CalculationTable>
  </Styled.CalculationTableContainer>
);

export const CalcTableHeader = (props) => (
  <tr>
    <Styled.CalculationHeaderCell>Age</Styled.CalculationHeaderCell>
    <Styled.CalculationHeaderCell>Year</Styled.CalculationHeaderCell>
    <Styled.CalculationHeaderCell>Invested</Styled.CalculationHeaderCell>
    <Styled.CalculationHeaderCell>Saved</Styled.CalculationHeaderCell>
  </tr>
);

export const CalcTableRow = (props) => {
  String.prototype.splice = function( idx, rem, s ) {
    return (this.slice(0,idx) + s + this.slice(idx + Math.abs(rem)));
  };
  function formatMoney(number) {
    var money = number.toFixed(2);
    for(var i=money.length-6; i>0; i = i-3) {
      money = money.splice(i,0,',');
    }
    return money.splice(0,0,'$');
  }

  return (
    <Styled.CalculationRow className={props.highlight && "highlight"}>
      <Styled.CalculationCell>{props.age}</Styled.CalculationCell>
      <Styled.CalculationCell class="cell2">{props.year}</Styled.CalculationCell>
      <Styled.CalculationCell class="cell3">{formatMoney(props.invested)}</Styled.CalculationCell>
      <Styled.CalculationCell class="cell4">{formatMoney(props.saved)}</Styled.CalculationCell>
    </Styled.CalculationRow>
  );
};

export const SavingsFooter = (props) => {
  return (
    <Styled.Footer>
      {props.children}
    </ Styled.Footer>
  );
};

export const Copyright = () => {
  return (
    <Styled.Copyright>
      © {new Date().getFullYear()} Seven Figure Savings
      <br />
      <br />
      <span style={{fontSize: "12px"}}>
        Designed &amp; Developed by
      </span>
      <br />
      Travis Johnson 
      (<Styled.Link href="https://www.twitter.com/iamtravjohnson" target="_blank">#iamtravjohnson</Styled.Link>)
      and Dustin Milam 
      (#DustinIsWhoIam)
    </Styled.Copyright>
  );
};































