import { React, useState } from "react";
import { Nav, NavLink, NavMenu, MobileTitle, Bars, Close }
    from "./NavbarElements";
 
const Navbar = () => {
  const [mobileOpened, setmobileOpened] = useState(false);

  return (
    <>
      <Nav className={mobileOpened && "mobile-opened"}>
        <NavMenu>
          <Close onClick={() => setmobileOpened(false)} />
          <NavLink to="/">
            About
          </NavLink>
          <NavLink to="/past-work">
            Past Work
          </NavLink>
          <NavLink to="/minesweeper">
            Minesweeper
          </NavLink>
          <NavLink to="/seven-figure-savings">
            Seven Figure Savings
          </NavLink>
        </NavMenu>
        <MobileTitle>Dustin Milam</ MobileTitle>
        <Bars onClick={() => setmobileOpened(true)} />
      </Nav>
    </>
  );
};
 
export default Navbar;