import { styled } from "styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 100px;
  margin: 0 auto;
  box-sizing: border-box;
  background: #d0d0d0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
  background: ${props => props.background};
  color: #fff;

  &:nth-child(odd) {
    justify-content: flex-end;

    &> div:first-child {
      order: 2;
    }

    &> div:last-child {
      justify-content: flex-end;
    }
    
    @media screen and (max-width: 1000px) {
      justify-content: center;
      
      &> div:last-child {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px;
    gap: 50px;

    &> div:first-child {
      order: 2;
    }
  }
`;

export const ScrollWrapper = styled.div`
  max-width: 100%;
  width: 600px;
`;

export const ScrollWindow = styled.div`
  overflow: scroll;
  padding-bottom: 56.25%;
  border-radius: 20px;
  position: relative;
  border: 5px solid #000;

  img {
    position: absolute;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    padding-bottom: 96.25%;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  align-items: center;
  flex-grow: 1;

  img {
    max-width: 200px;
  }

  h2 {
    font-family: "Varela Round", sans-serif;
    font-size: 60px;
    margin: 0;
  }

  @media screen and (max-width: 1000px) {
    justify-content: center;

    img {
      max-width: 150px;
    }

    h2 {
      font-size: 50px;
      text-align: center;
    }
  }

  @media screen and (max-width: 600px) {
    justify-content: center;

    img {
      max-width: 200px;
    }

    h2 {
      font-size: 40px;
    }
  }
`;



































