import { React } from "react";

import {
  BnBLogo,
  BrillliantPadLogo,
  DirtyCoastLogo,
  EyeSupplyLogo,
  OSEALogo,
  PaperTubeLogo,
  TascLogo,
  TheResetLogo,
  ThirdLoveLogo,
  UroTuningLogo
} from '../assets/logos';

import {
  BnBSite,
  BrillliantPadSite,
  DirtyCoastSite,
  EyeSupplySite,
  OSEASite,
  PaperTubeSite,
  TascSite,
  TheResetSite,
  ThirdLoveSite,
  UroTuningSite
} from '../assets/sites';

import { Banner, ScrollImg, LogoLabel } from "../components/About";

// console.log(bnb_site_resized, 'v');
const About = () => {

  return (
    <>
      <Banner color="#d3812a">
        <ScrollImg src={BnBSite} link="https://bnbtobacco.com" />
        <LogoLabel src={BnBLogo} name="BnB Tobacco" />
      </Banner>
      <Banner color="#4f7280">
        <ScrollImg src={BrillliantPadSite} link="https://www.brilliantpad.com/" />
        <LogoLabel src={BrillliantPadLogo} name="Brilliant Pad" />
      </Banner>
      <Banner color="#033a3f">
        <ScrollImg src={DirtyCoastSite} link="https://dirtycoast.com" />
        <LogoLabel src={DirtyCoastLogo} name="DirtyCoast" />
      </Banner>
      <Banner color="#379fcf">
        <ScrollImg src={EyeSupplySite} link="https://www.eyesupply.co.za" />
        <LogoLabel src={EyeSupplyLogo} name="EyeSupply" />
      </Banner>
      <Banner color="#1d4d41">
        <ScrollImg src={OSEASite} link="https://oseamalibu.com" />
        <LogoLabel src={OSEALogo} name="OSEA" />
      </Banner>
      <Banner color="#4f7280">
        <ScrollImg src={PaperTubeSite} link="https://papertube.co" />
        <LogoLabel src={PaperTubeLogo} name="PaperTube" />
      </Banner>
      <Banner color="#333e48">
        <ScrollImg src={TascSite} link="https://www.tascperformance.com" />
        <LogoLabel src={TascLogo} name="Tasc" />
      </Banner>
      <Banner color="#606060">
        <ScrollImg src={TheResetSite} link="https://thereset.com" />
        <LogoLabel src={TheResetLogo} name="TheReset" />
      </Banner>
      <Banner color="#eac4b3">
        <ScrollImg src={ThirdLoveSite} link="https://www.thirdlove.com/" />
        <LogoLabel src={ThirdLoveLogo} name="Third Love" />
      </Banner>
      <Banner color="#252525">
        <ScrollImg src={UroTuningSite} link="https://www.urotuning.com" />
        <LogoLabel src={UroTuningLogo} name="UroTuning" />
      </Banner>
    </>
  );
};
 
export default About;













