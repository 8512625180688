import React from "react";
import * as Styled from "./MineSweeperElements";

const Mine = (props) => {
  return (
    <Styled.MineCase>
      <Styled.MineSheen />
      <Styled.MineProngs />
    </Styled.MineCase>
  );
}

const Flag = (props) => {
  return (
    <>
      <Styled.FlagBanner />
      <Styled.FlagPole />
    </>
  );
}

const Face = () => {
  return (
    <Styled.Face>
      <Styled.Eye />
      <Styled.Eye />
    </ Styled.Face>
  );
}

export const MineSquare = (props) => {
  const showMine = (props.value === -1 && (props.setOff || (props.value === -1 && !props.flagged && props.gameState === "failure")));
  const showFalseFlag = (props.flagged && props.value !== -1 && props.gameState === "failure");
  const blankSquare = (!props.setOff && !props.flagged);
  const autoFlag = (props.value === -1 && !props.flagged && props.gameState === "victory");

  let classArray = [
    "outer-mine",
    ...props.setOff ? ["mine-value-"+props.value] : [],
    ...props.flagged ? ["flagged"] : [],
    ...showFalseFlag ? ["false-flag"] : [],
    ...blankSquare ? ["blank-square"] : [],
    ...autoFlag ? ["auto-flag"] : []
  ];

  return (
    <Styled.MineOuter
      className={classArray.length && classArray.join(" ")}
      onClick={props.onClick}
      onContextMenu={props.onContextMenu}
      {...props.onLongPress()}
      data-row={props.row}
      data-column={props.column}
      padding={props.calculatedPadding}
    >
      <Styled.MineInner fontSize={props.fontSize}>
        {(props.value > -1 && props.setOff) && props.value}
        {(props.flagged || autoFlag) && <Flag />}
        {showMine && <Mine />}
      </Styled.MineInner>
    </Styled.MineOuter>
  );
}

const BoardSetting = (props) => {
  return (
    <Styled.BoardSetting className={props.adjusted && "adjusted"}>
      <Styled.Incrementer direction="up" onClick={props.incrementUp}>+</Styled.Incrementer>
      <Styled.BoardInput name={props.name} min={props.min} max={props.max} value={props.value} readOnly />
      <Styled.Incrementer direction="down" onClick={props.incrementDown}>-</Styled.Incrementer>
      <Styled.BoardLabel>{props.label}</Styled.BoardLabel>
    </Styled.BoardSetting>
  );
}

const SideBar = (props) => {
  const maxMines = (props.boardSettings.rows * props.boardSettings.columns) - 1
  let gameMessage = "Mines Remain: " + props.minesRemain;
  if (props.gameState === "failure") {
    gameMessage = "It all goes boom.";
  } else if (props.gameState === "victory") {
    gameMessage = "Safe Now!";
  }

  return (
    <Styled.SideBar>
      <Styled.SideHeading>
        <Styled.H3>{gameMessage}</Styled.H3>
        <Styled.ResetButton onClick={props.resetClick}>
          <Face />
        </Styled.ResetButton>
      </Styled.SideHeading>
      <BoardSetting label="Rows" name="rows" min="1" max="20" 
        value={props.boardSettings.rows} 
        adjusted={props.boardSettings.rows !== props.currentBoardSettings.rows} 
        incrementUp={props.incrementUp} 
        incrementDown={props.incrementDown} 
      />
      <BoardSetting label="Columns" name="columns" min="1" max="20" 
        value={props.boardSettings.columns}
        adjusted={props.boardSettings.columns !== props.currentBoardSettings.columns} 
        incrementUp={props.incrementUp}
        incrementDown={props.incrementDown}
      />
      <BoardSetting label="Mines" name="mines" min="0" max={maxMines}
        value={props.boardSettings.mines}
        adjusted={props.boardSettings.mines !== props.currentBoardSettings.mines} 
        incrementUp={props.incrementUp}
        incrementDown={props.incrementDown}
      />
    </Styled.SideBar>
  );
}

export const MineField = (props) => {
  const gridWidth = Math.min(1190 * props.currentBoardSettings.columns/10 + 350, 1600) + "px";
  const minesRemain = props.currentBoardSettings.mines - props.flagsPlaced;
  const ratioPadding = (props.currentBoardSettings.rows / props.currentBoardSettings.columns) * 100;

  return (
    <Styled.Container gridWidth={gridWidth} className={props.gameState}>
      <Styled.MainContent>
        <Styled.GridOuter>
          <Styled.GridMid>
            <Styled.GridInner ratioPadding={ratioPadding}>{props.children}</Styled.GridInner>
          </Styled.GridMid>
        </Styled.GridOuter>
      </Styled.MainContent>
      <SideBar 
        boardSettings={props.boardSettings}
        currentBoardSettings={props.currentBoardSettings}
        resetClick={props.resetClick}
        handleOnChange={props.handleOnChange}
        minesRemain={minesRemain}
        incrementUp={props.incrementUp}
        incrementDown={props.incrementDown}
        gameState={props.gameState}
      />
    </Styled.Container>
  );
}

















































