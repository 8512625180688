import { React, useState } from 'react';
import { useWindowSize } from "@uidotdev/usehooks";
import { MineField, MineSquare } from '../components/Minesweeper';
import { useLongPress } from 'use-long-press';

const MineSweeper = () => {

  const windowSize = useWindowSize();
  const [gameState, setGameState] = useState("play");
  const [boardSettings, setboardSettings] = useState({
    rows: 10,
    columns: 10,
    mines: 15
  });
  const [mineField, setMineField] = useState(setUpMines());
  const [longPressed, setLongPressed] = useState(false);

  function incrementUp(e) {
    const updatedBoardSettings = {...boardSettings};
    const input = e.target.parentElement.querySelector('input');
    const currentValue = +input.value;
    const maxValue = +input.max;

    updatedBoardSettings[input.name] = Math.min(currentValue + 1, maxValue);
    updatedBoardSettings.mines = Math.min(updatedBoardSettings.mines, updatedBoardSettings.rows * updatedBoardSettings.columns - 1);

    setboardSettings(updatedBoardSettings);
  }

  function incrementDown(e) {
    const updatedBoardSettings = {...boardSettings};
    const input = e.target.parentElement.querySelector('input');
    const currentValue = +input.value;
    const minValue = +input.min;

    updatedBoardSettings[input.name] = Math.max(currentValue - 1, minValue);
    updatedBoardSettings.mines = Math.min(updatedBoardSettings.mines, updatedBoardSettings.rows * updatedBoardSettings.columns - 1);

    setboardSettings(updatedBoardSettings);
  }

  function handleOnChange(e) {
    setboardSettings({...boardSettings, [e.target.name]: +e.target.value});
  }

  function handleResetClick(e) {
    setMineField(setUpMines());
    setGameState("play");
  }

  function handleContextMenu(e) {
    e.preventDefault();
    if (gameState === "play" && !longPressed) {
      const coordinate = e.currentTarget.dataset
      const newField = flagSquare(+coordinate.row, +coordinate.column, mineField);
      setMineField(newField);
    }
  }

  const handleLongPress = useLongPress((e) => {
    setLongPressed(true);
    if (gameState === "play") {
      const coordinate = e.target.closest('.outer-mine').dataset;
      const newField = flagSquare(+coordinate.row, +coordinate.column, mineField);
      setMineField(newField);
    }
  }, {
    captureEvent: true,
    onFinish: (event, meta) => {
      setTimeout(() => setLongPressed(false), 100);
    },
  });

  function flagSquare(row, column, currentField) {
    let newField = [...currentField];
    const mineSquare = newField[row][column];
    if (!mineSquare.setOff) {
      mineSquare.flagged = !mineSquare.flagged;
      return newField;
    } else {
      return currentField;
    }
  }

  function handleMineClick(e) {
    e.preventDefault();
    if (gameState === "play" && !longPressed) {
      let newField = mineField;
      const coordinate = e.currentTarget.dataset
      if (isFirstClick(newField)) {
        while (newField[+coordinate.row][+coordinate.column].value === -1) {
          newField = setUpMines();
        }
      }
      newField = setOffSquare(+coordinate.row, +coordinate.column, newField, 0);
      setMineField(newField);
      setGameState(checkForVictory(newField));
    }
  }

  function setOffSquare(row, column, currentField, depth) {
    let newField = [...currentField];
    let mineSquare = newField[row][column];
    if (!mineSquare.setOff && !mineSquare.flagged) {
      mineSquare.setOff = true;
      if (mineSquare.value === 0) {
        fetchAdjacents(row, column, newField).forEach(function(adjacentCoordinate) {
          newField = setOffSquare(adjacentCoordinate.row, adjacentCoordinate.column, newField, depth + 1);
        });
      }
      return newField;
    } else {
      return currentField;
    }
  }

  function fetchAdjacents(row, column, field) {
    const board = currentBoardSettings(field);

    let adjacents = [];
    for (let a = row-1; a <=row+1; a++) {
      for (let b = column-1; b <= column+1; b++) {
        if(a>=0 && a<board.rows && b>=0 && b<board.columns) {
          adjacents.push({row: a, column: b});
        }
      }
    }

    return adjacents;
  }

  function mineCount(row, column, field) {
    let adjacents = fetchAdjacents(row, column, field);

    let mineArray = adjacents.map((coordinate) => {
      return field[coordinate.row][coordinate.column].value;
    });

    return mineArray.filter(x => x === -1).length;
  }

  function setUpMines() {
    let count = 0; //counts # of mines placed
    let saftey = 0;
    let mineField = Array(boardSettings.rows).fill(null).map(() => {
      return Array(boardSettings.columns).fill(null).map(() => {
        return {value: 0, setOff: false, flagged: false};
      });
    });

    while (count < boardSettings.mines && saftey < 10000) { //adds X number of random mines
      let i = Math.floor(Math.random()*boardSettings.rows); 
      let j = Math.floor(Math.random()*boardSettings.columns);
      if (mineField[i][j].value !== -1) {
        mineField[i][j].value = -1;
        count++;
      }
      saftey++;
    }

    for (let i = 0; i < boardSettings.rows; i++) { //assgins numbers to square according to surrounding mines
      for (let j = 0; j < boardSettings.columns; j++) {
        if (mineField[i][j].value !== -1) {

          mineField[i][j].value = mineCount(i, j, mineField);
        }
      }
    }

    return mineField;
  }

  function formMineField() {
    let grid = [];
    const board = currentBoardSettings(mineField);
    const padding = ((100/board.columns)/2);
    const minMaxScreenFontRatio = 0.0004918032468;
    const minMaxScreenFontRatioOffset = 0.282623;
    const fontSize = (Math.min(padding, 5) * 8 + 10) * Math.min(windowSize.width * minMaxScreenFontRatio + minMaxScreenFontRatioOffset, 1);

    for (let i = 0; i < board.rows; i++) {
      for (let j = 0; j < board.columns; j++) {
        grid.push(
          <MineSquare
            onClick={handleMineClick}
            onContextMenu={handleContextMenu}
            onLongPress={handleLongPress}
            gameState={gameState}
            value={mineField[i][j].value}
            setOff={mineField[i][j].setOff}
            flagged={mineField[i][j].flagged}
            row={i}
            column={j}
            calculatedPadding={padding}
            fontSize={fontSize}
            key={i*board.columns+j}
          />
        )
      }
    }

    return grid;
  }

  function seperateFieldArrays(field) {
    let mineArray = [];
    field.forEach(function(mines) {
      mineArray = [...mineArray, ...mines];
    });
    return mineArray;
  }

  function currentBoardSettings(field) {
    let mineArray = seperateFieldArrays(field);

    return {
      rows: field.length,
      columns: field[0].length,
      mines: mineArray.filter(function(mine) {
        return mine.value === -1;
      }).length
    }
  }

  function flagsPlaced(field) {
    let mineArray = seperateFieldArrays(field);

    return mineArray.filter(function(mine) {
      return mine.flagged;
    }).length;
  }

  function isFirstClick(field) {
    let mineArray = seperateFieldArrays(field);

    return mineArray.filter(function(mine) {
      return !!mine.setOff;
    }).length === 0;
  }

  function checkForVictory(field) {
    let mineArray = seperateFieldArrays(field);

    const triggeredMines = mineArray.filter(function(mine) {
      return (mine.setOff && mine.value === -1);
    });

    const untriggeredNotMines = mineArray.filter(function(mine) {
      return (!mine.setOff && mine.value > -1);
    });

    if (triggeredMines.length) {
      return "failure";
    } else if (untriggeredNotMines.length === 0) {
      return "victory";
    } else {
      return "play";
    }
  }
  
  return (
    <MineField
      flagsPlaced={flagsPlaced(mineField)}
      boardSettings={boardSettings}
      currentBoardSettings={currentBoardSettings(mineField)}
      gameState={gameState}
      resetClick={handleResetClick}
      handleOnChange={handleOnChange}
      incrementUp={incrementUp}
      incrementDown={incrementDown}
    >
      {formMineField()}
    </MineField>
  );
};

export default MineSweeper;

























