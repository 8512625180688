import { React, useState } from "react";
import * as Styled from "../components/SavingsTable/SavingsTableElements";
import { 
  SavingsHeader,
  Instruction,
  UserForm,
  BasicResults,
  CalcTable,
  CalcTableHeader,
  CalcTableRow,
  SavingsFooter,
  Copyright
} from '../components/SavingsTable';


const SevenFigureSavings = () => {

  const [pageAdjusted, setPageAdjusted] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [userInputs, setUserInputs] = useState({
    currentAge: 25,
    salary: 50000,
    amountSaving: 10,
    interestRate: 0,
    existingSavings: 0,
    retirementAge: 65
  });

  function handleClick(e) {
    setShowTable(!showTable);
  }

  function handleChange(e) {
    setPageAdjusted(true);
    setUserInputs({...userInputs, [e.target.name]: +e.target.value});
  }

  function finalResult(user) {
    const yearlyInvestment = user.salary * (user.amountSaving/100);
    const percentageRate = (user.interestRate/100);
    let saved = user.existingSavings;
    let year = -1;

    while (saved < 1000000 && year + user.currentAge < 120) {
      saved = (saved * (1 + percentageRate)) + yearlyInvestment;
      year++;
    }

    return {
      message: `Congratulations! You'll be a millionaire when you are ${year + user.currentAge} years old in ${new Date().getFullYear() + year}!`,
      success: (saved > 1000000)
    }
  }

  function createTable(user) {
    const yearlyInvestment = user.salary * (user.amountSaving/100);
    const percentageRate = (user.interestRate/100);
    const highlight = (user.existingSavings * (1 + percentageRate) + yearlyInvestment) >= 1000000;
    let tabelData = [
      <CalcTableRow
        highlight={highlight}
        age={user.currentAge}
        year={0}
        key={0}
        invested={user.existingSavings + yearlyInvestment}
        saved={(user.existingSavings * (1 + percentageRate) + yearlyInvestment)}
      />
    ];
    tabelData = createTableRow(tabelData, user, highlight);

    return tabelData;
  }

  function createTableRow(tableRows, user, highlighted = false) {
    const yearlyInvestment = user.salary * (user.amountSaving/100);
    const percentageRate = (user.interestRate/100);
    const prevRow = tableRows.slice(-1)[0];
    const newSaved = (prevRow.props.saved * (1 + percentageRate)) + yearlyInvestment;
    const newAge = prevRow.props.age + 1;
    const highlight = !highlighted && (newSaved > 1000000 || newAge === user.retirementAge)

    let newTableRows = [...tableRows];
    let newRow = (
      <CalcTableRow 
        highlight={highlight}
        age={newAge}
        year={prevRow.props.year + 1}
        key={prevRow.props.year + 1}
        invested={prevRow.props.invested + yearlyInvestment}
        saved={newSaved}
      />
    );

    newTableRows.push(newRow);

    if (
      newRow.props.age < 120 &&
      (newRow.props.saved < 1000000 || newRow.props.age < user.retirementAge)
    ) {
      newTableRows = createTableRow(newTableRows, user, (highlighted || highlight));
    }

    return newTableRows;
  }

  return (
    <Styled.Wrapper>

      <Styled.GlobalStyle />
      <SavingsHeader />

      <Styled.ContentContainer>
        <Instruction />
        <UserForm handleChange={handleChange} />

        {pageAdjusted && 
          <section>
            <BasicResults finalResult={finalResult(userInputs)} handleClick={handleClick} />
            <CalcTable showTable={showTable}>
              <CalcTableHeader />
              {createTable(userInputs)}
            </CalcTable>
          </section>
        }
      </Styled.ContentContainer>

      <SavingsFooter>
        <Copyright />
      </SavingsFooter>

    </Styled.Wrapper>
  )
};

export default SevenFigureSavings;





















