import React from 'react';
import { NavLink } from "react-router-dom";
import * as Styled from "../components/Index";
import * as SavingsStyled from "../components/SavingsTable/SavingsTableElements.js";

const Home = () => {
  return (
    <>
      <SavingsStyled.GlobalStyle />
      <SavingsStyled.Wrapper>
        <Styled.MyName>
          Dustin Milam
        </Styled.MyName>
        <SavingsStyled.ContentContainer>
          <SavingsStyled.StaticText style={{textAlign: 'left'}}>
            <p>Hello there! I'm a full stack developer with a solid 10 years under my belt, specializing in JavaScript, React, Ruby on Rails, AJAX & APIs, and slick mobile design. I've got a knack for crafting websites and e-commerce platforms that users love, and they're pretty good at driving up engagement and sales, too.</p>
            <p>I'm all about turning design ideas into smooth, efficient code, making sure everything runs like a dream, and playing nice with all browsers out there.</p>
            <p>I'm quick on the uptake when it comes to learning new systems and processes, so I'm ready to jump in and lend a hand to your team. Let's make some awesome projects happen!</p>
            <p>Feel free to take a look at these projects I've worked on with React to see what I'm capable of!</p>
            <ul style={{textAlign: 'left'}}>
              <li>I built a cool <Styled.NavLink to="/minesweeper">Minesweeper clone</Styled.NavLink> from scratch! It's just like the classic game you know and love.</li>
              <li>I also created a handy <Styled.NavLink to="/seven-figure-savings">compound interest calculator</Styled.NavLink>. It's perfect for figuring out how your money grows over time. Check it out!</li>
              <li>Here are some <Styled.NavLink to="/past-work">examples of the websites</Styled.NavLink> I had a hand in developing for the last 10 years!</li>
            </ul>
          </SavingsStyled.StaticText>
        </SavingsStyled.ContentContainer>
        <SavingsStyled.Footer>
          <SavingsStyled.Copyright style={{fontSize: "18px"}}>
            <span>Contact me at:</span>
            <br />
            <br />
            Phone: <SavingsStyled.Link href="tel:5403031366">(540) 303-1366</SavingsStyled.Link>
            <br />
            Email: <SavingsStyled.Link href="mailto:milamdustin87@gmail.com">milamdustin87@gmail.com</SavingsStyled.Link>
          </SavingsStyled.Copyright>
        </SavingsStyled.Footer>
      </SavingsStyled.Wrapper>
    </>
  );
};

export default Home;
