import { styled, createGlobalStyle } from "styled-components";
import { NavLink as Link } from "react-router-dom";
 
export const NavLink = styled(Link)`
  color: #5e6b54;
  font-weight: bold;
  transition: color .5s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #77876b;
  }
`;

export const MyName = styled.h1`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -2px;
  font-size: 58px;
  margin: 0;
  background: #5e6b54;
  padding: 50px 4%;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 38px;
  }
`;