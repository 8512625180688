import React from "react";
import * as Styled from "./AboutElements";

export const Banner = (props) => {
  return (
    <Styled.Container background={props.color}>
      {props.children}
    </Styled.Container>
  );
}

export const ScrollImg = (props) => {
  return (
    <Styled.ScrollWrapper>
      <Styled.ScrollWindow>
        <a href={props.link} target="_blank" rel="noreferrer">
          <img width={480} src={props.src} alt={props.link} />
        </a>
      </Styled.ScrollWindow>
    </Styled.ScrollWrapper>
  );
}

export const LogoLabel = (props) => {
  return (
    <Styled.LogoContainer>
      <img src={props.src} alt={props.name} />
      <h2>{props.name}</h2>
    </Styled.LogoContainer>
  );
}




































