import styled from "styled-components";
import { css } from 'styled-components';

const flagWidth = 500;
const flagHeight = 220;
const gridPadding = 7;

const device = {
  xs: '400px',
  sm: '600px',
  md: '850px',
  lg: '1280px',
  xl: '1440px',
  xxl: '1920px',
};

const media = {
  xs: (...args) => css`
    @media (max-width: ${device.xs}) {
      ${css(...args)};
    }
  `,
  sm: (...args) => css`
    @media (max-width: ${device.sm}) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (max-width: ${device.md}) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (max-width: ${device.lg}) {
      ${css(...args)};
    }
  `,
  xl: (...args) => css`
    @media (max-width: ${device.xl}) {
      ${css(...args)};
    }
  `,
  xxl: (...args) => css`
    @media (max-width: ${device.xxl}) {
      ${css(...args)};
    }
  `,
}

export const Container = styled.section`
  width: 100%;
  max-width: ${props => props.gridWidth};
  padding: 20px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  ${media.md`
    flex-wrap: wrap;
  `}
`;

export const MainContent = styled.main`
  flex-grow: 1;
  width: calc(100% - 250px);
  position: relative;

  ${media.md`
    width: 100%;
    order: 2;
  `}
`;

export const SideBar = styled.aside`
  flex-basis: 225px;
  background: #ddd;
  border: 5px solid #eee;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  padding: 20px;
  margin-left: 25px;
  box-sizing: border-box;

  ${media.md`
    margin-left: 0;
    margin-bottom: 20px;
    order: 1;
  `}
`;

export const GridOuter = styled.div`
  width: 100%;
  height: 100%;
  background: #ccc;
  border: 5px solid #ddd;
  margin-bottom: 0;
  padding: ${gridPadding}px;
  border-right-color: #bbb;
  border-bottom-color: #bbb;
  box-sizing: border-box;

  ${media.md`
    border-width: 3px;
    padding: 4px;
  `}
`;

export const GridMid = styled.div`
  border: 5px solid #ddd;
  border-left-color: #bbb;
  border-top-color: #bbb;
  background: #ddd;
  box-sizing: border-box;

  ${media.md`
    border-width: 3px;
  `}
`;

export const GridInner = styled.div`
  padding-bottom: ${props => "calc("+props.ratioPadding+"%)"};
`;

export const MineOuter = styled.div`
  padding: ${props => props.padding}%;
  position: relative;
  background: #ccc;
  box-sizing: border-box;
  float: left;
  font-weight: bold;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none;
  user-select: none;
`;

export const MineInner = styled.div`
  font-size: ${props => (props.fontSize)}px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border: 5px solid #ddd;
  border-right-color: #bbb;
  border-bottom-color: #bbb;
  display: flex;
  align-items: center;
  justify-content: center;

  .play .blank-square &:hover {
    border-color: #ddd;
    border-left-color: #bbb;
    border-top-color: #bbb;
  }

  [class*="mine-value"] & {
    border: 1px solid #bbb;
    cursor: default;
  }

  .mine-value--1 & {background: #C40B07;}
  .mine-value-0 & {font-size: 0;}
  .mine-value-1 & {color: #0000ff;}
  .mine-value-2 & {color: #008800;}
  .mine-value-3 & {color: #ff0000;}
  .mine-value-4 & {color: #030C7E;}
  .mine-value-5 & {color: #7F040A;}
  .mine-value-6 & {color: #118080;}
  .mine-value-7 & {color: #000000;}
  .mine-value-8 & {color: #808080;}
  .false-flag &:before,
  .false-flag &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 4%;
    height: 84%;
    background: #C40B07;
    z-index: 1;
  }

  .false-flag &:after {transform: translate(-50%, -50%) rotate(45deg);}

  ${media.md`
    border-width: 2px;
  `}
`;

export const FlagBanner = styled.div`
  position: absolute;
  top: 33.9%;
  left: 57.3%;
  transform: translate(-50%, -50%);

  width: 0;
  height: 0;

  padding-top: 17%;
  padding-bottom: 17%;
  padding-left: 39%;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-top: -${flagHeight}px;
    margin-left: -${flagWidth}px;

    border-top: ${flagHeight}px solid transparent;
    border-bottom: ${flagHeight}px solid transparent;
    border-left: ${flagWidth}px solid #028F0E;
  }

  .auto-flag &:after {
    border-left-color: #00d300;
  }

  ${media.md`
    padding-top: 25.5%;
    padding-bottom: 25.5%;
    padding-left: 58.5%;
  `}
`;

export const FlagPole = styled.div`
  position: absolute;
  top: 50%;
  left: 36%;
  transform: translate(-50%, -50%);
  width: 6%;
  height: 70%;
  background: #000;

  ${media.md`
    left: 30%;
    width: 7%;
    height: 90%;
  `}
`;

export const MineCase = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000;
  height: 33%;
  width: 33%;
  border-radius: 50%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 140%;
    width: 15%;
    background: #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${media.md`
    height: calc(40% + 2px);
    width: calc(40% + 2px);
    max-width: 30px;
    max-height: 30px;

    .mine-value--1 & {
      height: calc(40% + 1px);
      width: calc(40% + 1px);
    }
  `}
`;

export const MineProngs = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: -1;

  &:before,
  &:after {
    content: '';
    position: absolute;
    height: 127%;
    width: 11%;
    background: #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:after {
    transform: translate(-50%, -50%) rotate(135deg);
  }
`;

export const MineSheen = styled.div`
  position: absolute;
  top: 34%;
  left: 34%;
  width: 20%;
  height: 20%;
  transform: translate(-50%, -50%);
  z-index: 1;
  background: #fff;
`;

export const SideHeading = styled.div`
  display: flex;
  align-items: center;
`;

export const ResetButton = styled.div`
  border: 3px solid #eee;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  width: 50px;
  height: 50px;
  display: flex;
  margin-left: 10px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &:hover {
    border: 3px solid #eee;
    border-left-color: #ccc;
    border-top-color: #ccc;
  }
`;

export const Face = styled.div`
  background: #F7FF19;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  border: 2px solid #000;
  position: relative;

  &:after {
    content: '';
    border-bottom: 4px solid #000;
    border-radius: 50%;
    height: 25%;
    width: 50%;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);

    .failure & {
      top: 80%;
      border-top: 3px solid #000;
      border-bottom: none;
    }

    .victory & {
      border-bottom-width: 7px;
    }
  }
`;

export const Eye = styled.div`
  background: #000;
  width: 16%;
  height: 16%;
  border-radius: 50%;
  position: absolute;
  top: 30%;
  left: 32%;
  transform: translate(-50%, -50%);

  & + & {
    left: 68%;
  }

  .failure & {
    background: none;
    width: 26%;
    height: 26%;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      content: '';
      width: 20%;
      height: 100%;
      background: #000;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .victory & {
    border-bottom: 5px solid #F7FF19;
    height: 16%;
    top: 38%;
  }
`;

export const H3 = styled.h3`
  text-align: left;
  margin: 0;
  flex-grow: 1;

  &:first-child {
    margin-top: 0;
  }
`;
export const BoardSetting = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
`; 

export const BoardLabel = styled.label`
  margin-left: 10px;
`;

export const Incrementer = styled.span`
  border: 2px solid #eee;
  border-right-color: #ccc;
  border-bottom-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 23px;
  cursor: pointer;

  input + & {
    border-left: 0;
    font-size: 14px;
  }

  &:first-child {
    border-right: 0;
  }
`; 

export const BoardInput = styled.input.attrs({ 
  type: 'number'
})`
  text-align: center;
  border-radius: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: none;
  }

  .adjusted & {
    border: 2px solid #ccc;
    border-bottom-color: #eee;
    border-right-color: #eee;
    border-left-color: #ccc;
  }

  width: 30px;
  box-sizing: border-box;
  outline: none;
  background: #ddd;
  border: 2px solid #eee;
  border-bottom-color: #ccc;
  border-right: 0;
  border-left: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;























