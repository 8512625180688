import { FaBars } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
 
export const Nav = styled.nav`
  background: #000;
  height: 85px;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem calc((100vw - 650px) / 2);
  position: relative;
  z-index: 12;
  b
`;
 
export const NavLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  font-weight: bold;

  &.active {
    color: #b1ffb3;
  }
`;

export const MobileTitle = styled.h2`
  display: none;
  color: #fff;
  font-size: 40px;
  font-family: "Varela Round", sans-serif;
  margin: 19px;

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
 
export const Bars = styled(FaBars)`
  display: none;
  color: #808080;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 8px;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

export const Close = styled(MdClose)`
  display: none;
  color: #808080;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
 
export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  @media screen and (max-width: 768px) {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
    background: #000;
    flex-direction: column;
    padding: 25px;
    margin: 0;
    border-radius: 0 0 0 10px;
    border: 2px solid #303030;
    border-top: 0;
    border-right: 0;
    transition: transform .4s;
    transform: translate(100%);

    .mobile-opened & {
      transform: none;
    }

    a {
      padding: 10px 0;
    }
  }
`;

























