import { styled, createGlobalStyle, keyframes } from "styled-components";

const pulseAnimation = keyframes`
  0% { width: 264px; }
  30% { width: 264px; }
  40% { width: 274px; }
  50% { width: 264px; }
  60% { width: 264px; }
  70% { width: 274px; }
  80% { width: 264px; }
  100% { width: 264px; }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    background: #cdd4c8;
    font-family: 'Raleway', sans-serif;
  }
`;

export const Link = styled.a`
  color: #5e6b54;
  font-weight: bold;
  transition: color .5s;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #77876b;
  }
`;

export const Wrapper = styled.main`
  width: 100%;
  max-width: 1100px;
  min-height: 100%;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0,0,0,.3);
  position: relative;
  background: #fff;
`;

export const ContentContainer = styled.main`
  background: #f0f2ee;
  padding: 50px 0;
`;

export const SiteName = styled.h1`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -2px;
  font-size: 58px;
  margin: 0;
  background: #5e6b54;
  padding: 50px 4%;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 38px;
  }
`;

export const Subtitle = styled.h2`
  color: #77876b;
  margin: -19px auto 0;
  font-size: 17px;
  text-align: center;
  background: #fff;
  font-weight: 100;
  border: #5e6b54 solid 1px;
  border-radius: 50px;
  width: 275px;
  padding: 8px 0 9px;
  float: left;
  position: absolute;
  left: 0;
  right: 0;
`;

export const StaticText = styled.p`
  padding: 0 4%;
  text-align: center;
  clear: both;
  font-size: 25px;
  margin: 0 0 50px;
  font-family: 'Raleway', sans-serif;
  font-weight: 200;
`;

export const InputContainer = styled.div`
  display: flex;
  gap: 1.4%;
  padding: 0 4%;

  @media screen and (max-width: 480px) {
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const InputUnitContainer = styled.div`
  @media screen and (max-width: 480px) {
    flex-basis: 48.3%;

    & + & + & {
      margin-top: 15px;
    }
  }

  label {
    text-align: center;
    display: block;
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    min-height: 45px;
    box-sizing: border-box;
    background: #77876b;
    color: #fff;
    font-weight: 200;
    border-radius: 5px 5px 0 0;
    margin-bottom: 0;
    padding-top: 15px;
    border: none;
  }

  input {
    width: 100%;
    height: 150px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    color: #555;
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    border-radius: 0 0 5px 5px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      display: none;
    }
  }
`;

export const ResultsBanner = styled.div`
  margin: 50px 0 50px;
  padding: 50px 4%;
  background: #0c0;
  color: #fff;
  border-top: #ccc solid 1px;
  border-bottom: #ccc solid 1px;
  text-align: center;
  clear: both;
  font-size: 25px;

  &.sorry {
    background: #e34a51;
  }
`;

export const ShowButton = styled.button`
  cursor: pointer;
  background: #000;
  color: #fff;
  padding: 24px 30px;
  margin: -85px auto 0;
  width: 264px;
  display: inherit;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  border-radius: 5px;
  border: 0;
  font-weight: bold;
  white-space: nowrap;

  animation-name: ${pulseAnimation};
  animation-duration: 5s;
  transform-origin:70% 70%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  &:hover {
    background: #333;
  }
`;

export const CalculationTableContainer = styled.div`
  transition: max-height 0.7s;
  max-height: 0;
  overflow: hidden;

  &.open {
    max-height: 4200px;
  }
`;

export const CalculationTable = styled.table`
  width: 91%;
  margin: 50px auto 0;
  text-align: center;
  border: none;
  font-weight: 700;
  min-height: .01%;
  overflow-x: auto;
  border-spacing: 0;
  border-collapse: collapse;
`;

export const CalculationHeaderCell = styled.th`
  border: 1px solid #ddd;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  background: #77876b;
  color: #fff;
  padding: 15px !important;
`;

export const CalculationRow = styled.tr`
  &.highlight {
    background-color: #0c0 !important;
    color: #fff;
  }

  &:nth-child(even) {
    background-color: #dee3db;
  }
`;

export const CalculationCell = styled.td`
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border: 1px solid #ddd;
`;

export const Footer = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 50px 0 50px;
  text-align: center;
  border-top: #ccc solid 1px;
  background: #fff;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  font-weight: 200;
  line-height: 1.42857143;
  color: #333;
`;

export const Copyright = styled.div`
  margin-left: 50px;
  text-align: left;
`;

export const ShareButtons = styled.div`
  margin-right: 50px;
`;

























